<template>
    <CModal
      title="Cargar Archivo"
      :color="color"
      size="lg"
      :show.sync="uploadModal"
      style="z-index: 100001"
    >   
        <CForm id="file_form" method="POST" enctype="multipart/form-data">
            <CRow>
              <CCol md="12">
                <CInput
                    type="file"
                    name="file"
                    id="file"
                    :label="label"
                    :lazy="false"
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="upload" :color="color">Cargar Archivo</CButton>
        </template>
    </CModal>
</template>

<script>
import challenges_ws from '../../../services/challenges';
import store from '../../../store'

export default {
  name: 'TravelModal',
  components: {
    
  },
  props: {

  },
  data () {
    return {
      uploadModal: false,
      color: "info",
      challenge_id: 0,
      question_id: 0,
      label: "Archivo:",
      question: false
    }
  },
  mounted: async function() {

  },
  methods: {
    show (title, challenge, question) {
      this.uploadModal = true;
      this.color = "info";
      
      this.label = question.question;

      this.challenge_id = challenge.id;
      this.question_id = question.id;

      this.question = question;
    },
    async upload () {
      const form = document.getElementById("file_form");
      const formData = new FormData(form);

      formData.append("travel_id", this.challenge_id);
      formData.append("question_id", this.question_id);

      let response = false;
      response = await challenges_ws.uploadQuestion(formData);
    
      if(response.type == "success"){
        this.$emit("upload", this.challenge, this.question, response.data );
      }

      this.showToast(response.type, response.message);

      this.closeModal();
    },
    closeModal () {
      this.uploadModal = false;
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>